@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Muli';
  src: local('Muli'), url('./fonts/Muli-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Muli';
  src: url('./fonts/Muli-Regular.ttf') format('truetype');
}

body {
  font-family: 'Muli' !important;
}

.line:first-of-type {
  display: none !important;
}
